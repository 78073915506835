
@font-face {
    font-family: "Feast Flash";
    src: url("../asset/fonts/feast-of-flesh-bb.regular.ttf");
}
@font-face {
    font-family: "SF Pro Italic";
    src: url("../asset/fonts/SFPRODISPLAYBLACKITALIC.OTF");
}
@font-face {
    font-family: "SF Pro Display Bold";
    src: url("../asset/fonts/SFPRODISPLAYBOLD.OTF");
}
@font-face {
    font-family: "SF Pro Regular";
    src: url("../asset/fonts/SFPRODISPLAYREGULAR.OTF");
}