.flex-box{
    display: flex;
}
.flex-justify-start{
    justify-content: left;
}
.flex-align-center{
    align-items: center;
}
.left-auto{
    margin-left: auto;
}
.uppercase-text{
    text-transform: uppercase;
}

.header-bg {
    background-image: url('../public/images/header-bg/header-bg.png');
    background-size: 100% 100%;
    background-position: center;
    padding: 20px 23px 11px 24px;
}
.header-btn{
    background: linear-gradient(90deg,#223B75,#27579E,#2964B0);
    border-radius: 30px;
    padding: 12px 16px;
    margin-right: 6px;
    border: none;
}
.header-btn-text{
    cursor: pointer;
    color: #FFF;
    font-size: 10px;
    line-height: 9px;
    font-family: "SF Pro Display Bold";
}
.heading-mt-mb{
    margin-top: 5px;
    margin-bottom: 4px;
}
.text-for {
    font-size: 36px;
    color: #F68825;
    line-height: 43px;
    text-transform: lowercase;
    font-family: "SF Pro Display Bold";
}
.text-heading{
    font-size: 35px;
    line-height: 42px;
    text-transform: uppercase;
    font-family: "Feast Flash";
}

.padding-selfie{
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 21px;
}
.selfie-content{
    display: flex;
    justify-content: space-between;
    color: #010101;
}
.selfie-box{
    width: 44px;
    height: 44px;
    border-radius: 7px;
    border: 0.25pt solid #01010145;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.selfie-text{
    font-size: 10px;
    font-family: "SF Pro Regular";
}
.selfie-count{
    font-size: 21px;
    align-self: center;
    margin-left: 8px;
    font-family:"SF Pro Display Bold"; 
}
.pledge-count{
    font-size: 21px;
    font-weight: bold;
    align-self: center;
    margin-left: 12px;
}
.not-required{
    font-family: "SF Pro Regular";
}
.selfie{
    display: flex;
}
.support{
    margin-top: 24px;
    background-image: url("../public/images/Rectangle\ 2.png");
    background-repeat: no-repeat;
    background-size:100% 100%;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;

}
.boy-image img{
    object-fit: cover;
}
.creative-btn{
    border: none;
    background: linear-gradient(#223B75,#27579E,#2964B0);
    color: white;
    padding: 12px 36px 12px 28px;
    border-radius: 64px;
    font-size: 14px;
    margin: auto;
    font-family: "SF Pro Display Bold";
}
.mobile-not-required{
    display: flex;
}
.creative{
    padding-top: 7px;
    padding-bottom: 27px;
    padding-left: 30px;
    padding-right: 30px;
}
.mobile-not-required div{
    margin-left: auto;
    font-size: 11px;
}
.click-creative{
  display:flex;
  padding-top: 12px;
}
.support-logo{
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translate(-50%, -0%);
}
.pledge{
    /* width: 144px; */
    /* width: 40%; */
    /* height: 124px; */
    color: white;
}

.pledge-main{
    display: flex;
    background-color: #D2D2D1;
    justify-content: space-between;
    padding: 26px 24px;
}
.width-100{
    width: 100%;
}
.m-negative{
    margin-left: -18px;
}
.font-11{
    font-size: 11px;
}
.pledge-aims{
    padding: 18px 18px;
    background: linear-gradient(0deg,#E12A26,#E64125,#F17124,#F58323);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.pledge-text{
    width: min(144px,100%);
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    font-family: "SF Pro Display Bold";
}
.pledge-btn{
    margin-top: 11px;
    padding: 9px 28px;
    border-radius: 18.45px;
    border: none;
    line-height: 15px;
    font-size: 13px;
    font-family:"SF Pro Display Bold";
    text-transform: uppercase;
}
.we-aiims{
    padding-top: 42px;
    padding-bottom: 56px;
    padding-left: 24px;
    padding-right: 24px;
}
.aiims-box{
    width: 30%;
    height: 126px;
    flex: 0 0 auto;
    background-color: #B7B7B7;
    border-radius: 6px;
    margin-bottom: 22px;
}
.justify-content-end{
    justify-content: space-between;
}
.we-aiims-text{
    font-family: "SF Pro Display Bold";
    text-align: center;
    font-size: 22px;
}
.flex-wrap{
    flex-wrap: wrap;
}
/* camera css */
.black-background{
    background-color: black !important;
}
.support-camera{
    margin-top: 24px;
    background-repeat: no-repeat;
    background-size:100% 100%;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    background-color: #EAEAEA;
    border-radius: 20px;
}
.click-photo{
    background-color:#41B655 ;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.click-photo-border{
    padding: 5px;
    border: 2px dashed #41B655;
    width: 62px;
    height: 62px;
    border-radius: 50%;
}
.close-photo{
    background-color:#ED2224 ;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-photo-border{
    padding: 5px;
    border: 2px dashed #ED2224;
    width: 62px;
    height: 62px;
    border-radius: 50%;
}
.capture-image{
    display: flex;
    justify-content: center;
    padding-top: 53px;
    padding-bottom: 64px;
    border-bottom: 10px solid #D2D2D1;
}
.margin-95{
    margin-left: 95px;
}
.text-center{
    text-align: center;
}
.social-icons{
    padding-top: 40px;
    padding-bottom: 26px;
}
.social-icons ul{
   list-style-type: none;
   display: flex;
   gap:13px;
   margin: 0px;
   padding: 0px;
   justify-content: center;
}
.capture-logo{
    position: absolute;
    right: 22px;
    top: 0px;
}
.share-icon{
    padding: 11px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A3A3A2;
    border-radius: 64px;
}
.share-icon span{
    margin-left: 6px;
    font-family: "SF Pro Display Bold";
}
.my-pledge{
    background: linear-gradient(329deg,#F58423,#E37E2A,#B36D3C,#67535A,#223B74);
    /* height: 328px; */
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    border-radius: 20px;
    margin-top: 24px;
    padding-top: 76px;
    padding-bottom: 65px;
}
.my-pledge-text{
    width: min(209px,100%);
}
.my-pledge-content{
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: "SF Pro Display Bold";
    line-height: 31px;
}
.my-pledge-content::before{
    content: url("../public/images/left-hand.png");
    position: relative;
    top: -47px;
    left: -15px;
}
.my-pledge-content::after{
    content: url("../public/images/right-hand.png");
    position: relative;
    bottom: -52px;
}
.take-pledge-btn{
    cursor: pointer;
    border: none;
    background:linear-gradient(90deg,#223B75,#27579E,#2964B0);
    padding: 8px 50px 11px 44px;
    color: white;
    border-radius: 64px;
    font-family: "SF Pro Display Bold";
    font-size: 19px;
}
.take-pledge{
    display: flex;
    justify-content: center;
    padding-top: 26px;
    padding-bottom: 34px;
}
.margin-right-10{
    margin-right: 10px;
}
.margin-left-10{
    margin-left: 10px;
}
.cursor{
    cursor: pointer;
}
.margin-left-14{
    margin-left: 14px;
}
.border-radius-20{
    border-radius: 20px;
}
.photo-text{
    font-size: 12x;
    font-family: "SF Pro Regular";

}
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}
.more-button{
    cursor: pointer;
    text-align: center;
    background-color: grey;
    color: white;
    padding: 10px 0px ;
    width: 30%;
}
.more-btn-detail{
    display: flex;
    justify-content: center;
}
.image-pledge{
    display: flex;
    justify-content: center;
    padding-top: 24px;
}
.image-pledge img{
    width: 90%;
    border-radius: 25px;
}
.grey-color{
    color: grey;
}
.download-btn{
    font-size: 7px;
    display: flex;
    align-items: center;
    font-family: "SF PRO DISPLAY BOLD";
    gap: 5px;
}